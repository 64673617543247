/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-large.less 2013-1-16 *******/
body {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
}
.desk {
  max-width: 871px;
  width: 100%;
  margin: 0 auto;
}
.cb-layout3 .section--main .desk {
  max-width: 960px;
}
#home {
  width: 300px;
  float: left;
  margin: 28px 0 0 -27px;
}
.cb-elastic-content-small #home {
  width: 230px;
  margin: 11px 0 0 -27px;
}
#head {
  margin-top: 104px;
}
.cb-layout2 #head {
  margin-top: 119px;
}
.cb-layout2 #head h1 {
  width: 631px;
  margin-left: 120px;
  margin-right: 120px;
}
.cb-layout3 #head {
  width: 100%;
  margin-left: -0.52083333%;
  margin-top: 130px;
}
.cb-layout3 #head h1 {
  width: 98.95833333%;
  margin-left: 0.52083333%;
  margin-right: 0.52083333%;
}
.cb-layout4 #head {
  margin-top: 198px;
}
/*#expo {
  height: 650px;
}*/
.north {
  top: 227px;
}
.cb-layout2 .north {
  top: 207px;
}
.cb-layout1 .main {
  width: 881px;
}
.cb-layout1 .main > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 881px;
}
.cb-layout1 .main h2,
.cb-layout1 .main .foot {
  margin-right: 5px;
  margin-left: 5px;
}
.cb-layout1 .main .part,
.cb-layout1 .main > .grid table {
  margin-right: 5px;
  margin-left: 5px;
  width: 871px;
}
.cb-layout1 .main .tiny {
  width: 430px;
}
.cb-layout1 .main > .slim {
  width: 440.5px;
}
.cb-layout1 .main > .slim .part,
.cb-layout1 .main > .slim.grid table {
  width: 430.5px;
}
.cb-layout1 .main > .slim h2,
.cb-layout1 .main > .slim .foot,
.cb-layout1 .main > .slim .part,
.cb-layout1 .main > .slim.grid table {
  margin-right: 5px;
  margin-left: 5px;
}
.cb-layout2 .main {
  width: 871px;
}
.cb-layout2 .main > .unit {
  margin-right: 120px;
  margin-left: 120px;
  width: 631px;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 0px;
  margin-left: 0px;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 0px;
  margin-left: 0px;
  width: 631px;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 631px;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 0px;
  margin-left: 0px;
}
.cb-layout3 .main {
  width: 960px;
}
.cb-layout3 .main > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 960px;
}
.cb-layout3 .main h2,
.cb-layout3 .main .foot {
  margin-right: 5px;
  margin-left: 5px;
}
.cb-layout3 .main .part,
.cb-layout3 .main > .grid table {
  margin-right: 5px;
  margin-left: 5px;
  width: 950px;
}
.cb-layout3 .main > .slim {
  width: 480px;
}
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  width: 470px;
}
.cb-layout3 .main > .slim h2,
.cb-layout3 .main > .slim .foot,
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  margin-right: 5px;
  margin-left: 5px;
}
.cb-layout4 .main {
  width: 881px;
}
.cb-layout4 .main > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 881px;
}
.cb-layout4 .main h2,
.cb-layout4 .main .foot {
  margin-right: 5px;
  margin-left: 5px;
}
.cb-layout4 .main .part,
.cb-layout4 .main > .grid table {
  margin-right: 5px;
  margin-left: 5px;
  width: 871px;
}
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  width: 871px;
}
.cb-layout4 .main > .slim h2,
.cb-layout4 .main > .slim .foot,
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  margin-right: 5px;
  margin-left: 5px;
}
.base {
  width: 901px;
}
.base > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 901px;
}
.base h2,
.base .foot {
  margin-right: 15px;
  margin-left: 15px;
}
.base .part,
.base > .grid table {
  margin-right: 15px;
  margin-left: 15px;
  width: 871px;
}
.base > .slim {
  width: 300.33333333px;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 270.33333333px;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 15px;
  margin-left: 15px;
}
.side {
  width: 881px;
}
.side > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 881px;
}
.side h2,
.side .foot {
  margin-right: 5px;
  margin-left: 5px;
}
.side .part,
.side > .grid table {
  margin-right: 5px;
  margin-left: 5px;
  width: 871px;
}
.side > .slim {
  width: 440.5px;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 430.5px;
}
.side > .slim h2,
.side > .slim .foot,
.side > .slim .part,
.side > .slim.grid table {
  margin-right: 5px;
  margin-left: 5px;
}
.south {
  width: 881px;
}
.south > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 881px;
}
.south h2,
.south .foot {
  margin-right: 5px;
  margin-left: 5px;
}
.south .part,
.south > .grid table {
  margin-right: 5px;
  margin-left: 5px;
  width: 871px;
}
.south .tiny {
  width: 430px;
}
.south > .slim {
  width: 440.5px;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 430.5px;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 5px;
  margin-left: 5px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.north .unit {
  width: 72.90470723%;
  margin-left: 0;
}
.north .unit .part {
  margin-top: 22px;
  margin-bottom: 22px;
}
.cb-layout1 .main {
  margin-left: -0.57405281%;
}
.cb-layout1 .main > .unit {
  margin-top: 112px;
  margin-bottom: 112px;
}
.cb-layout1 .main > .unit:first-child {
  margin-top: 0;
}
.cb-layout1 .main > .unit .part {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-layout2 .main {
  margin-bottom: 54px;
}
.cb-layout2 .main:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout2 .main .unit {
  margin-top: 25px;
  margin-bottom: 0;
  padding-bottom: 26px;
  border-bottom: 1px solid #d9d9d9;
}
.cb-layout2 .main .unit .part {
  margin-top: 18px;
  margin-bottom: 0;
}
.cb-layout2 .main .unit .part .loud {
  margin-bottom: 37px;
}
.cb-layout2 .main > .unit:first-child {
  margin-top: 0;
}
.cb-layout2 .main > .unit:first-child .part:first-child {
  margin-top: 5px;
}
.cb-layout3 .main {
  margin-top: 22px;
  margin-bottom: 158px;
  margin-left: -0.52083333%;
}
.cb-layout3 .main:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout3 .main .unit {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout3 .main .unit .part {
  margin-top: 13px;
  margin-bottom: 13px;
}
.cb-layout3 .main .unit .part.lead + .part {
  margin-top: 0;
}
.cb-layout4 .main {
  margin-bottom: 164px;
  margin-left: -0.57405281%;
}
.cb-layout4 .main:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout4 .main > .unit {
  margin-top: 24px;
  margin-bottom: 24px;
}
.cb-layout4 .main > .unit:first-child {
  margin-top: 0;
}
.cb-layout4 .main > .unit .part {
  margin-top: 10px;
  margin-bottom: 9px;
}
.base {
  margin-left: -1.72215844%;
  margin-bottom: 25px;
}
.cb-layout2 .base {
  margin-top: 0;
  margin-bottom: 54px;
}
.cb-layout2 .base:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.base:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.base .unit {
  margin-top: 45px;
  margin-bottom: 45px;
}
.base > .unit.wide:first-child {
  margin-top: 0;
  margin-bottom: 102px;
}
.base > .unit.wide:first-child .part:first-child {
  margin-top: 10px;
}
.base .unit.slim {
  width: 29.96670366%;
  margin: -1px 1.66481687% 0;
  padding: 22px 0 44px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.base .unit.slim .part {
  margin: 14px 0;
}
.base .unit.slim .part.link {
  margin: 17px 0;
}
.side {
  margin-top: 145px;
  margin-bottom: 173px;
  margin-left: -0.57405281%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.cb-layout2 .side {
  margin-top: 45px;
  margin-bottom: 54px;
}
.cb-layout2 .side:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.side:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.side .unit,
.side .unit.slim {
  margin-top: 8px;
  margin-bottom: 8px;
}
.side .unit .part,
.side .unit.slim .part {
  margin-top: 8px;
  margin-bottom: 8px;
}
.south {
  margin-top: 83px;
  margin-bottom: 83px;
  margin-left: -0.57405281%;
}
.south:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.south > .unit {
  margin-top: 21px;
  margin-bottom: 21px;
}
.south > .unit .part {
  margin-top: 13px;
  margin-bottom: 13px;
}
.south > .unit .part.pict {
  margin-top: 5px;
  margin-bottom: 5px;
}
.south > .unit .part.link {
  margin-top: 13px;
  margin-bottom: 13px;
}
.south > .unit .part.link .open {
  padding: 12px 46px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -500px;
  width: 1000px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
h1 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.11111111;
}
.cb-layout2 h1 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.2;
}
.cb-layout4 h1 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.25;
}
.link .open {
  padding: 12px 44px;
}
.loud {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
}
div.north {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.27272727;
}
div.north div.part.text {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
div.north h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 40px;
  font-size: 4rem;
  line-height: 0.7;
  font-weight: normal;
}
.main {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
  text-align: center;
}
.main h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.11111111;
  font-weight: normal;
}
.main h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 0.96153846;
  font-weight: normal;
}
.main .pict > img,
.main .pict > a > img,
.main .file > img,
.main .file > a > img,
.main .film > img,
.main .film > a > img,
.main .play > img,
.main .cb-googlemapscontainer,
.main .cb-zoom-image {
  margin: 0 auto;
}
.main .unit.slim {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.33333333;
}
.main .link .open {
  float: right;
}
.cb-layout2 .main {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
  text-align: left;
}
.cb-layout2 .main h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1;
  font-weight: normal;
  color: #154b99;
}
.cb-layout2 .main h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 44px;
  font-size: 4.4rem;
  line-height: 1.2;
  font-weight: normal;
}
.cb-layout2 .main .loud {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.1875;
  font-weight: 400;
  color: #31afe1;
}
.cb-layout2 .loud {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.1875;
  font-weight: 400;
  color: #31afe1;
}
.cb-layout2.c2 .loud {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
}
.cb-layout3 .main {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2;
  text-align: left;
}
.cb-layout3 .main h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: normal;
}
.cb-layout4 .main {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.27272727;
  text-align: left;
}
.cb-layout4 .main h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.11111111;
  font-weight: normal;
}
.cb-layout4 .main h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 0.96153846;
  font-weight: normal;
}
.base {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.33333333;
  text-align: center;
}
.base h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.11111111;
  font-weight: normal;
}
.base h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 0.96153846;
  font-weight: normal;
}
.base .pict > img,
.base .pict > a > img,
.base .file > img,
.base .file > a > img,
.base .film > img,
.base .film > a > img,
.base .play > img,
.base .cb-googlemapscontainer,
.base .cb-zoom-image {
  margin: 0 auto;
}
.side {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  text-align: left;
}
.side h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1;
  font-weight: normal;
}
.side h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 0.96153846;
  font-weight: normal;
}
.side .pict > img,
.side .pict > a > img,
.side .file > img,
.side .file > a > img,
.side .film > img,
.side .film > a > img,
.side .play > img,
.side .cb-googlemapscontainer,
.side .cb-zoom-image {
  margin: 0 auto;
}
.south {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
  text-align: center;
}
.south .unit.slim {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.33333333;
}
.south .link .open {
  float: right;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.33333333;
}
.south h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.11111111;
  font-weight: normal;
}
.south h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 0.96153846;
  font-weight: normal;
}
.south .pict > img,
.south .pict > a > img,
.south .file > img,
.south .file > a > img,
.south .film > img,
.south .film > a > img,
.south .play > img,
.south .cb-googlemapscontainer,
.south .cb-zoom-image {
  margin: 0 auto;
}
.area .form .name {
  padding-bottom: 10px;
}
.area input,
.area textarea {
  font-size: 18px;
  font-size: 1.8pxrem;
  line-height: 1.22222222;
}
.area input.text,
.area input.sign,
.area input.password,
.area textarea,
.area input.hint,
.area textarea.hint,
.area input.capt {
  padding: 6px 4px;
}
.area textarea,
.area textarea.hint {
  padding: 22px 8px;
}
.area input.submit,
.area button.cb-hybrid {
  min-height: 70px;
  margin-top: 31px;
  padding: 12px 44px;
  float: right;
  font-size: 22px;
  font-size: 2.2rem;
}
#expo {
  float: left;
  position: relative;
  width: 100%;
  margin-top: 60px;
  height: 0;
  padding-bottom: 62.5%;
}
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}
#expo div.link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
#expo div.link .prev,
#expo div.link .next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  width: 100px;
  height: 100px;
}
#expo div.link .prev {
  left: 0;
  background: url('/images/arr_prev.png') no-repeat center;
}
#expo div.link .next {
  right: 0;
  background: url('/images/arr_next.png') no-repeat center;
}
#expo div.link .cb-index {
  margin: 0 auto;
}
#expo div.link .cb-index .cb-index-all {
  position: absolute;
  bottom: 22px;
}
#expo div.link .cb-index-all a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: inline-block;
  width: 11px;
  height: 11px;
  margin: 0 6px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.218s;
}
#expo div.link .cb-index-all .this a {
  background: #ffffff;
}
#expo div.link .cb-index-some {
  display: none;
}
.mood-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 700px;
  margin-top: 60px;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,000000+70&0+0,0.4+70 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 70%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 70%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 70%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#66000000', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
#expo {
  height: 700px;
  padding-bottom: 0;
}
#slides {
  height: 700px !important;
}
#slides .slide {
  height: 700px !important;
}
#navbar.dock {
  display: block;
  float: right;
  margin-right: 80px;
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2.85714286;
  text-transform: uppercase;
}
#navbar.dock .meta {
  display: block;
  float: left;
  height: 40px;
  padding: 35px 24px 35px 23px;
  border-right: 1px solid #e5e5e5;
  color: #000;
  transition: all 0.218s;
}
#navbar.dock .meta:hover,
#navbar.dock .meta:focus {
  background-color: #2eaadc;
}
.cb-elastic-content-small #navbar.dock .meta {
  padding-top: 10px;
  padding-bottom: 10px;
}
#navbar.dock .meta:first-child {
  border-left: 1px solid #e5e5e5;
}
#navbar.dock .service_search {
  padding-left: 58px;
  padding-right: 58px;
  background: url('/images/search.png') no-repeat center center;
  font-size: 0px;
}
.c2 .service_jobs,
.c3 .service_about,
.c4 .service_contact {
  background: #2eaadc;
}
.togglenavigation {
  top: 41px;
  width: 40px;
  height: 27px;
  transition: top 0.218s;
}
.cb-elastic-content-small .togglenavigation {
  top: 16px;
}
#mobile-navi {
  width: 290px;
  right: -290px;
}
#mobile-navi .navi.sub1 {
  margin-top: 110px;
  transition: margin 0.218s;
}
.cb-elastic-content-small #mobile-navi .navi.sub1 {
  margin-top: 60px;
}
#mobile-navi .navi .item .menu {
  font-size: 20px;
  font-size: 2rem;
}
#mobile-navi .navi.sub2 .item .menu {
  padding: 7px 20px 7px 35px;
  font-size: 18px;
  font-size: 1.8rem;
}
.cb-layout2 .section--footer {
  margin-top: 90px;
}
.section--footer__content {
  width: 100%;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  text-align: center;
}
.section--footer__content .content--containter {
  width: 100%;
  margin: 80px 0 85px;
  float: left;
}
.section--footer__content .content--containter.content--containter--partner {
  margin: 60px 0 70px;
}
.section--footer__content .content--containter .footer—content__vcard {
  margin: 70px 0 45px;
}
.section--footer__content .content--containter .vcard,
.section--footer__content .content--containter .col--container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.38888889;
  text-align: left;
}
.section--footer__content .content--containter .vcard .col,
.section--footer__content .content--containter .col--container .col {
  position: relative;
}
.section--footer__content .content--containter .vcard .col > span,
.section--footer__content .content--containter .col--container .col > span {
  display: block;
}
.section--footer__content .content--containter .vcard .col .link--maps .link--maps__icon,
.section--footer__content .content--containter .col--container .col .link--maps .link--maps__icon {
  position: absolute;
  top: 3px;
  left: 0;
}
.section--footer__content .content--containter .vcard .col .link--maps .link--maps__text,
.section--footer__content .content--containter .col--container .col .link--maps .link--maps__text {
  display: inline-block;
  padding-left: 57px;
}
.section--footer__content .content--containter .vcard .col .img,
.section--footer__content .content--containter .col--container .col .img {
  width: 100%;
}
.section--footer__content h2 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.38888889;
  font-weight: normal;
}
.section--footer__content h3 {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 0.96153846;
  font-weight: normal;
}
.section--footer__content .org {
  font-family: 'Nunito Sans', 'helvetica', helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.25;
}
.u-hidden-small {
  display: inline;
}
/*# sourceMappingURL=./screen-large.css.map */